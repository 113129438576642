import gsap from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import ScrollSmoother from "./libs/gsap/ScrollSmoother.3.11.5.min.js";

window.addEventListener('DOMContentLoaded', () => loadHandler())


function loadHandler() {
    gsap.registerPlugin(ScrollTrigger, ScrollSmoother)

    scrollSmootherInit()
    preloadInit()


}


function scrollSmootherInit() {
    if (ScrollTrigger.isTouch !== 1) {
        window.scrollY = 0
        window.pageYOffset = 0
        ScrollSmoother.create({
            wrapper: '.smooth-wrapper',
            content: '.smooth-content',
            smooth: 3,
            effects: true,
        })
    }

    const toggleHandler = (event) => {
        if (event.detail.target.getAttribute('data-toggle-height') !== null) {
            setTimeout(() => {
                ScrollTrigger.refresh(true)
            }, 200)
        }
    }
    document.addEventListener('toggleopen', toggleHandler)
    document.addEventListener('toggleclose', toggleHandler)
}

function preloadInit() {
    window.addEventListener('load', () => {
        setTimeout(() => {
            document.body.classList.add('loaded')
        }, 200)
    })
}

document.addEventListener('click', (event) => {
    if (!event.target.closest('a[href]')) return
    const href = event.target.getAttribute('href');

    if (!href) return;
    if (href[0] != '#' || href == '#') return;

    event.preventDefault();
    ScrollSmoother.get().scrollTo(href, true)
})